.proj-container {
    font-family: 'Tahoma';
    display: inline-block;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 2vw;
    padding: 2vw;
    width: 20vw;
    height: 15vw;
    text-align: left;
}
.proj-name {
    margin: 0;
    padding: 0;
    font-size: 2.5vw;
    font-weight: bold;
    color: #1C1C1E;
}
.proj-year {
    margin: 0 0 0.5vw 0;
    padding: 0;
    font-size: 1.75vw;
    color: #F1A957;
}
.proj-link {
    font-size: 1vw;
    color: #7376B8;
    border: transparent;
    outline: transparent;
    background-color: transparent;
    margin: 1vw 0;
    padding: 0 0;
}
.proj-desc {
    font-size: 1vw;
    color: #1C1C1E;
    margin: 0;
    padding: 0;
    margin-top: 1vw;
}

@media only screen and (max-width: 600px) {
    .proj-container {
        width: 52vw;
        height: 40vw;
        padding: 4vw;
    }
    .proj-name {
        font-size: 6vw;
    }
    .proj-year {
        font-size: 4vw;
    }
    .proj-link {
        font-size: 3vw;
        padding-top: 2vw;
    }
    .proj-desc {
        font-size: 3vw;
        padding-top: 2vw;
    }
}