.lang-container {
    display: flex;
    width: min-content;
    font-family: 'Tahoma';
    margin: 0;
    padding: 0;

}

.lang-jot {
    width: 2vw;
    height: auto;
    margin: 0;
    padding: 0;
}

.lang-item {
    font-size: 1.5vw;
    margin: 0;
    padding: 0;
    padding-left: 1vw;
    color: #1C1C1E;
    background-color: white;
}

@media only screen and (max-width: 600px) {
    
    .lang-jot {
        width: 4vw;
        height: 4vw;
    }
    
    .lang-item {
        font-size: 3.5vw;
        color: #1C1C1E;
    }

}