.front-home {
    text-align: center;
    background-image: url("../assets/homeImage.jpg");
    background-size: 100vw auto;
    background-color: #1C1C1E;
    color: white;
    margin: 0;
    padding: 5vw 0;
}
.about-container {
    margin: 2vw 0;
    padding: 0;
}
.name-container {
    display: flex;
    justify-content: center;
}
.my-name {
    font-size: 3vw;
    font-weight: bold;
    margin: 0;
    padding: 0;
}
.my-name2 {
    margin: 0;
    padding: 0;
    padding-left: 1vw;
    font-size: 3vw;
    font-weight: bold;
    color: #F1A957;
}
.my-program {
    margin: 0;
    padding: 0;
    font-size: 2vw;
}
.front-logo {
    width: 9vw;
    height: auto;
}
.skill-card {
    background-color: white;
    font-family: 'Tahoma';
    margin: 10vw 20vw 5vw 20vw;
    padding: 2.5vw 2vw 0 2vw;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}
.skill-title {
    padding: 0.5vw 0;
    margin: 0vw 0vw 0 0vw;
    font-size: 4vw;
    color: #1C1C1E;
    text-align: center;
}
.skill-list {
    padding: 0 1.5vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 2vw;
    margin: 0;
}
.lang-block {
    padding: 0vw 5vw 4vw 5vw;
}
.lang-title {
    color: #1C1C1E;
    margin: 2vw 0;
}
.project-card {
    font-family: 'Tahoma';
    margin: 10vw 0 0 0;
}
.project-title {
    margin: 1vw 0;
    padding: 0.5vw 0;
    font-size: 4vw;
    color: #1C1C1E;
    text-align: center;
}
.project-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.contact-title {
    margin: 5vw 0 2vw 0;
    padding: 0.5vw 0;
    font-size: 2vw;
    color: #1C1C1E;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .front-home {
        background-size: 120vw auto;
    }
    .my-name, .my-name2 {
        font-size: 5vw;
    }
    .my-program {
        font-size: 3vw;
    }
    .front-logo {
        width: 25vw;
    }
    .skill-list {
        padding: 5%;
        background-color: white;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: column;
        font-size: 5vw;
    }
    .skill-title {
        font-size: 8vw;
        margin: 0;
        margin-top: 3vw;
        text-decoration: underline double #7376B8;
    }
    .project-card {
        margin: 15vw 0 0 0;
    }
    .project-title {
        font-size: 8vw;
        margin: 5vw 0;
        text-decoration: underline double #7376B8;
    }
    .project-list {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .contact-card {
        margin: 10vw 0 4vw 0;
    }
    .contact-title {
        font-size: 6vw;
    }
}