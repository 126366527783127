.content-container {
    display: flex;
}
.logo-pic {
    width: 5vw;
    height: 5vw;
    margin: 1vw 1vw 5vw 1vw;
}

@media only screen and (max-width: 600px) {
    .logo-pic {
        width: 9vw;
        height: 9vw;
    }
}